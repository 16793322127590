import React from 'react';

const MatrixOfHL7 = () => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let width = (canvas.width = window.innerWidth);
    let height = (canvas.height = window.innerHeight);
    let columns = Math.floor(width / 20); // Number of columns based on character width
    const characters = `MSH|^~\&|HL7❤️||LOVE|REMY||⚕️ORM^O01❤️|0001|1|❤️2.5.⚡️❤️||THANKS~DAD||❤️-U-MOM|8859/1|3THINGS|TRYFREE|`;
    const charArray = characters.split('');
    let drops: any = [];
    for (let i = 0; i < columns; i++) drops[i] = Math.floor(Math.random() * (height / 20));
    let frameRate = 30;
    let lastFrameTime = Date.now();
    const draw = () => {
      ctx.fillStyle = 'rgba(45, 55, 72, 0.5)';
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = 'rgba(103, 232, 249, 0.9)';
      ctx.font = '16px monospace';
      for (let i = 0; i < drops.length; i++) {
        let group = '';
        for (let j = 0; j < 11; j++) group += charArray[Math.floor(Math.random() * charArray.length)];
        ctx.fillText(group, i * 20, drops[i] * 20);
        if (drops[i] * 20 > height && Math.random() > 0.975) drops[i] = 0;
        drops[i]++;
      }
    };

    const animate = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastFrameTime;
      if (elapsedTime > 1000 / frameRate) {
        draw();
        lastFrameTime = currentTime;
      }
      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      width = canvas.width = window.innerWidth;
      height = canvas.height = window.innerHeight;
      columns = Math.floor(width / 20);
      drops = [];
      for (let i = 0; i < columns; i++) drops[i] = 1;
    };

    // Check if the user is on a mobile device before handling resize and scroll events
    const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
    if (!isMobileDevice) window.addEventListener('resize', handleResize);

    return () => {
      if (!isMobileDevice) window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <canvas className="matrix-canvas fixed top-0 left-0" ref={canvasRef}></canvas>;
};

export default MatrixOfHL7;
