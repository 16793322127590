/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import logo from '../../../images/retrohook.png';

export default function AuthenticatedLoader(props: any) {
  return (
    <div className="flex flex-col justify-center items-center animate-color-change-2x dark:animate-color-change-2x-dark h-screen">
      <div
        className="items-center animate-pulse"
        onClick={(e) => {
          props.handleNavClick(e, '#top', 'Home');
        }}
      >
        <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
        <h2 className="mt-0 text-center text-3xl font-bold text-gray-600 dark:text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>
          Retrohook
          <span className="inline align-middle text-xs tracking-tight text-cyan-300 pl-1" />
        </h2>
      </div>
    </div>
  );
}
