/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export function PrivateRoute({ ...rest }) {
  const { isAuthenticated, logout } = useAuth0();
  if (!isAuthenticated) {
    logout();
    return <Redirect to={{ pathname: '/' }} />;
  }
  return <Route {...rest} />;
}
