import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CogIcon } from '@heroicons/react/24/outline';
import InputBox from "../../../Base/Elements/Input/InputBox";
import TimeDropdown from './TimeDropdown';

interface SFTPConfigModalProps {
  isOpen: boolean;
  workflow: any;
  closeModal: () => void;
  onChangeUsername: (event: any) => void;
  onChangePort: (event: any) => void;
  onChangePath: (event: any) => void;
  onChangeHost: (event: any) => void;
  onChangeCadence: (event: any) => void;
  onChangeCert: (event: any) => void;
  onChangeKey: (event: any) => void;
}

export default function SFTPConfigModal({ isOpen, workflow, closeModal, onChangeHost, onChangePath, onChangePort, onChangeUsername, onChangeCadence, onChangeCert, onChangeKey }: SFTPConfigModalProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom animate-color-change-2x rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="animate-color-change-2x px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full animate-color-change-2x sm:mx-0 sm:h-10 sm:w-10">
                    <CogIcon className="h-9 w-9 text-gray-100" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="mt-2 text-2xl leading-6 font-medium text-gray-100">SFTP Configuration</Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 space-y-5">
                  <InputBox name="Host" onChange={onChangeHost} defaultValue={workflow?.trigger?.host} placeholder="sftp://example.com" />
                  <InputBox name="Port" onChange={onChangePort} defaultValue={workflow?.trigger?.port} placeholder="22" />
                  <InputBox name="Path" onChange={onChangePath} defaultValue={workflow?.trigger?.path} placeholder="/path/to/files" />
                  <TimeDropdown selected={workflow.trigger.cadence} selectHandler={onChangeCadence} />                
                  <InputBox name="Username" onChange={onChangeUsername} defaultValue={workflow?.trigger?.username} placeholder="username" />
                  <div className="relative border border-gray-500 rounded px-3 py-2 shadow-sm focus-within:ring-1 
                  focus-within:ring-cyan-400 focus-within:border-cyan-400">
                    <label htmlFor='Key' className="z-5 absolute -top-2 left-2 -mt-px inline-block px-1 bg-gray-700 rounded-md text-xs font-medium text-gray-100">Key</label>
                    <textarea className="block  bg-transparent w-full border-0 p-0 h-auto text-cyan-400 placeholder-cyan-200 focus:ring-0 sm:text-sm"
                      id="key-trigger" 
                      name="key-trigger"
                      rows={ 2 } 
                      defaultValue={ workflow?.trigger?.key }  
                      onChange={ async (event) => onChangeKey(event) }
                    />
                  </div>
                  <div className="relative border border-gray-500 rounded px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-400 focus-within:border-cyan-400">
                    <label htmlFor='Certificate' className="z-5 absolute -top-2 left-2 -mt-px inline-block px-1 bg-gray-700 rounded-md text-xs font-medium text-gray-100">Certificate</label>
                    <textarea className="block bg-transparent w-full border-0 p-0 h-auto text-cyan-400 placeholder-cyan-200 focus:ring-0 sm:text-sm"
                      id="cert-trigger" 
                      name="cert-trigger"
                      rows={ 2 } 
                      defaultValue={ workflow?.trigger?.cert }  
                      onChange={ async (event) => onChangeCert(event) }
                      />
                  </div>
                </div>
              </div>
              <div className="animate-color-change-2x px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={closeModal}>
                  Save
                </button>
                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-transparent text-base font-medium text-gray-100 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
