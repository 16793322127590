/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/button-has-type */
import { ComputerDesktopIcon, SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../../context/ThemeContext';

// eslint-disable-next-line react/function-component-definition
const ThemeToggler = () => {
  const { theme, toggleTheme } = useTheme();

  const handleToggle = () => {
    switch (theme) {
      case 'light':
        toggleTheme('dark');
        break;
      case 'dark':
        toggleTheme('system');
        break;
      case 'system':
        toggleTheme('light');
        break;
      default:
        toggleTheme('system');
        break;
    }
  };

  return (
    <div>
      <button onClick={handleToggle} className="p-2 rounded-full focus:outline-none">
        {theme === 'light' && <SunIcon className="h-8 w-8 text-yellow-500" />}
        {theme === 'dark' && <MoonIcon className="h-8 w-8 text-yellow-100" />}
        {theme === 'system' && <ComputerDesktopIcon className="h-8 w-8 text-gray-500" />}
      </button>
    </div>
  );
};

export default ThemeToggler;
