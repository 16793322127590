/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../context/ThemeContext';
import ChatwootWidget from '../components/Base/Layout/ChatWootWidget';
import {
  AuthenticatedLoader,
  FeaturesGrid,
  MatrixOfHL7,
  TermsOfServiceModal,
  PricingBox,
  RetrohookOverview,
  ContactUs,
  RetrohookHome,
  ProductNav,
} from '../components/Product';

const classNames = (...classes: any) => classes.filter(Boolean).join(' ');

export default function Product(props: any) {
  const matrixCSS = '';
  const { effectiveTheme } = useTheme();
  const { isAuthenticated } = useAuth0();
  const [showTermsOfService, setShowTermsOfService] = React.useState(false);
  const [matrix, setMatrix] = React.useState<any>(false);
  const [currentSection, setCurrentSection] = React.useState('Home');

  const handleNavClick = (e: any, href: string, sectionName: string) => {
    e.preventDefault();
    if (href.startsWith('http')) {
      // eslint-disable-next-line no-undef
      window.location.href = href;
    } else {
      setCurrentSection(sectionName);
      // eslint-disable-next-line no-undef
      const targetSection = document.querySelector(href);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const closeModal = () => {
    setShowTermsOfService(false);
    setMatrix(!matrix);
  };

  if (isAuthenticated) return <AuthenticatedLoader handleNavClick={handleNavClick} />;
  const { notify, signUp, login } = props;

  return (
    <main id="top">
      {matrix ? (
        <MatrixOfHL7 />
      ) : (
        <ProductNav login={login} currentSection={currentSection} handleNavClick={handleNavClick} matrix={matrix} setMatrix={() => setMatrix(!matrix)} />
      )}
      <ChatwootWidget />
      {showTermsOfService ? <TermsOfServiceModal open={showTermsOfService} notify={notify} closeModal={closeModal} /> : null}
      <div id="home" className="pt-2 *:lg:overflow-hidden bg-cyan-500 dark:animate-color-change-2x-dark border-b-2 border-b-cyan-400">
        {matrix ? <MatrixOfHL7 /> : null}
        <RetrohookHome
          classNames={classNames}
          matrix={matrix}
          matrixCSS={matrixCSS}
          signUp={() => signUp()}
          setMatrix={() => setMatrix(!matrix)}
          setShowTermsOfService={() => setShowTermsOfService(!showTermsOfService)}
        />
      </div>
      <div id="overview" className="relative pt-6 animate-color-change-2x dark:animate-color-change-2x-dark">
        {matrix ? <MatrixOfHL7 /> : <RetrohookOverview classNames={classNames} effectiveTheme={effectiveTheme} matrix={matrix} matrixCSS={matrixCSS} />}
      </div>
      <div id="features" className="relative animate-color-change-2x dark:animate-color-change-2x-dark">
        {matrix ? <MatrixOfHL7 /> : <FeaturesGrid />}
      </div>
      <div id="pricing">
        {matrix ? (
          <MatrixOfHL7 />
        ) : (
          <div className="relative items-center animate-color-change-2x dark:animate-color-change-2x-dark">
            <div className=" py-8">
              <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <PricingBox setRegisterModal={() => signUp()} setMatrix={() => setMatrix(!matrix)} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div id="contact" className="border-t-2 border-t-cyan-400 dark:bg-cyan-500 dark:bg-gradient-to-r dark:from-cyan-300 pb-2">
        {matrix ? <MatrixOfHL7 /> : <ContactUs classNames={classNames} effectiveTheme={effectiveTheme} matrix={matrix} />}
      </div>
    </main>
  );
}
