import React from 'react';
import { DocumentTextIcon, CalendarDaysIcon, PlayCircleIcon, ChatBubbleLeftRightIcon, ArrowDownRightIcon } from '@heroicons/react/24/outline';
import help from '../../../images/help.png';
import AWSMarket from '../../../images/aws-mkt.png';
import AWSMarketDark from '../../../images/aws-mkt-dark.png';

export default function ContactUs(props: any) {
  const { classNames, effectiveTheme, matrix } = props;
  return (
    <>
      <div className="relative md:absolute md:left-0 md:w-1/2">
        <img src={help} className="~h-72/96" alt="Retrohook takes you back in time" />
      </div>
      <div className="relative mx-auto max-w-md px-4 sm:max-w-7xl sm:px-6 py-auto">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <div className={classNames(matrix ? 'px-12 py-4 m-2 border-2 border-gray-600 rounded' : '', 'cursor-pointer')}>
            <h2 className="text-lg font-semibold text-cyan-300 align-middle md:pt-12">&nbsp;</h2>
            <p
              className=" text-gray-700 dark:text-white text-3xl font-bold tracking-tight sm:text-4xl sm:tracking-tight"
              style={{ fontFamily: '"Gugi", sans-serif' }}
            >
              Get Started with Retrohook
            </p>
            <p className="mt-4 text-xl text-gray-600 dark:text-gray-100">
              Check out our documentation to learn how to get started with Retrohook. When you are ready we are here to help.
            </p>
            <div className="my-6 w-full flex flex-wrap ~gap-x-2/4 gap-y-2">
              <a
                href="https://docs.retrohook.com"
                className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md text-indigo-400 hover:text-indigo-500"
                rel="noreferrer"
                target="_blank"
              >
                <DocumentTextIcon className="-ml-1 mr-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                Docs
              </a>
              <a
                href="https://www.youtube.com/channel/UCsgby_S_K27scdBlvvOJK8A"
                rel="noreferrer"
                target="_blank"
                className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium text-cyan-400 hover:text-cyan-300 dark:text-red-400 dark:hover:text-red-500"
              >
                <PlayCircleIcon className="-ml-1 mr-1 h-5 w-5 text-cyan-500 dark:text-red-400" aria-hidden="true" />
                Youtube
              </a>
              <a
                href="https://calendly.com/clint_johnson/retrohook?hide_gdpr_banner=1"
                rel="noreferrer"
                target="_blank"
                className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium text-purple-400 hover:text-purple-500"
              >
                <CalendarDaysIcon className="-ml-1 mr-1 h-5 w-5 text-purple-500" aria-hidden="true" />
                Schedule a Demo
              </a>
              <div>
                <a
                  href="#/contact"
                  onClick={() => {
                    window.$chatwoot.toggle('open');
                  }}
                  className="inline-flex items-center pl-3 pr-1 py-2 border border-transparent text-base font-medium rounded-md text-pink-500 hover:text-pink-500"
                >
                  <ChatBubbleLeftRightIcon className="-ml-1 mr-1 h-5 w-5 text-pink-500" aria-hidden="true" />
                  Chat
                </a>
                <ArrowDownRightIcon className="inline h-5 w-6 mt-2 text-pink-400" aria-hidden="true" />
              </div>
              <a
                type="button"
                target="_blank"
                rel="noreferrer"
                href="https://aws.amazon.com/marketplace/pp/prodview-efbfzy3xjgx5s"
                className="inline ~px-1/3 ~py-1/2 dark:text-cyan-100 text-gray-500 text-center items-center ~text-sm/lg hover:border-2 dark:hover:border-cyan-500 hover:border-cyan-400 rounded"
              >
                Available on <img className="~h-5/8 inline ml-2 m-2" src={effectiveTheme !== 'dark' ? AWSMarketDark : AWSMarket} alt="AWS Marketplace" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
