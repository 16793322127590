/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';
import ThemeToggler from '../Base/Layout/ThemeToggler';

import logo from '../../../images/retrohook.png';

const nav = [
  { name: 'Home', href: '#top', current: true },
  { name: 'Features', href: '#features' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'Docs', href: 'https://docs.retrohook.com/' },
  { name: 'Contact', href: '#contact' },
];

export default function ProductNav(props: any) {
  const { currentSection, handleNavClick, matrix, login, setMatrix } = props;

  const getNavItemClass = (name: string) => {
    return name === currentSection
      ? 'px-3 py-1 text-base font-medium text-gray-700 dark:text-gray-50 border-b-2 border-pink-500'
      : 'px-3 py-1 text-base font-medium text-gray-700 dark:text-gray-50 hover:border-b-2 hover:border-b-cyan-400';
  };

  return (
    <Popover as="header" className="sticky top-0 z-10 border-b-2 border-cyan-400 animate-color-change-2x dark:animate-color-change-2x-dark">
      <div className="py-2">
        <nav className="mx-auto flex items-center justify-between sm:pl-3" aria-label="Global">
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <button type="button" onClick={(e) => handleNavClick(e, '#top', 'Home')}>
                <img className="h-10 inline px-1" src={logo} alt="logo" />
                <span className="inline align-middle text-3xl text-gray-700 dark:text-gray-100" style={{ fontFamily: '"Gugi", sans-serif' }}>
                  Retrohook
                </span>
              </button>
              <div className="mr-4 flex items-center md:hidden">
                <Popover.Button className="dark:bg-gray-700 bg-gray-100 rounded-md p-2 inline-flex items-center justify-center dark:text-gray-100 text-gray-700 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="hidden space-x-8 md:flex md:ml-10">
              {nav.map((item) => (
                <a key={item.name} href={item.href} className={getNavItemClass(item.name)} onClick={(e) => handleNavClick(e, item.href, item.name)}>
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <ThemeToggler />
          <div className="hidden md:flex pr-4">
            <button
              type="button"
              onClick={() => {
                login();
                setMatrix(!matrix);
              }}
              className="px-2 py-1 cursor-pointer border-2 border-solid border-cyan-300 hover:border-gray-50 text-sm font-medium rounded text-cyan-600 bg-gray-100 hover:bg-gray-100"
            >
              Sign In
            </button>
          </div>
        </nav>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden dark:animate-color-change-2x-dark">
          <div className="rounded-lg px-6 shadow-md ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <button type="button" onClick={(e) => handleNavClick(e, '#top', 'Home')}>
                <img className="h-10 inline px-1 shadow-sm" src={logo} alt="logo" />
                <span className="inline align-middle text-2xl tracking-tight font-bold text-gray-700 dark:text-gray-100 pl-1">Retrohook</span>
              </button>
              <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-700 dark:text-gray-50 hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="pt-5 pb-6">
              <div className="px-2 space-y-1">
                {nav.map((item) => (
                  <a key={item.name} href={item.href} onClick={(e) => handleNavClick(e, item.href, item.name)} className={getNavItemClass(item.name)}>
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
