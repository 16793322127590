/* eslint-disable react/react-in-jsx-scope */
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HomeIcon } from '@heroicons/react/24/outline';
import useFetch from 'use-http';
import WorkspaceDropdown from './WorkspaceDropdown';
import config from '../../../../../config';

export default function WorkspaceSelectModal(props: any) {
  const { get, response } = useFetch(config.url);
  const [workspaces, setWorkspaces] = useState<any>(props.workspaces);
  const [selected, setSelected] = useState<any>(props.selectedWorkspace ? props.selectedWorkspace : 'Select a Workspace');

  const getAccountWorkspaces = async () => {
    const workspacesRequest: any = await get(`account/get-account/${props?.user?.sub}`);
    if (response.ok && workspacesRequest?.account?.workspaces && workspacesRequest.account.workspaces.length > 0) {
      setWorkspaces(workspacesRequest.account.workspaces);
    }
  };

  useEffect(() => {
    if (!workspaces) getAccountWorkspaces();
    setSelected(props.selectedWorkspace);
  }, [props.workspaces, props.selectedWorkspace]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto text-sm" onClose={props.closeSelectModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom animate-color-change-2x dark:animate-color-change-2x-dark rounded-lg px-4 pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="px-1 inline-flex">
                <HomeIcon className="h-8 w-8 text-gray-600 dark:text-gray-200 animate-color-change-2x dark:animate-color-change-2x-dark rounded-md" />
                <Dialog.Title as="h3" className="text-xl font-medium pl-2 text-gray-600 dark:text-gray-200">
                  Change Workspace
                </Dialog.Title>
              </div>

              <WorkspaceDropdown workspaces={props.workspaces} selectedWorkspace={selected} setSelectedWorkspaceHandler={props.setSelectedWorkspaceHandler} />

              <div className="mt-4">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border hover:bg-cyan-300 shadow-sm px-4 py-2 text-base font-medium text-gray-600 dark:text-gray-100 bg-gray-100 dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    props.closeSelectModal();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
