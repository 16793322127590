/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
import { CheckIcon } from '@heroicons/react/24/solid';

export default function PricingBox(props: any) {
  const installationPrice = 2000;
  const recurringPrice = 2000;

  const developer = [
    'No-code workflow builder',
    'Native EHR Event Automation',
    'Shared environment access',
    'Team mode collaboration',
    'Access to documentation',
  ];

  const core = ['Everything in Developer', 'Pro features (S3, FHIR, SFTP, Tunnels)', '1 No-code AWS private environment', 'Two 1 hour training sessions'];

  const updatesFeatures = ['Feature updates and improvements', 'Maintenance and security patches', '48 Hour Response Support', 'Access to community forums'];

  return (
    <div className="pb-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <p className="my-5 text-4xl font-bold text-gray-500 dark:text-gray-200 sm:text-center" style={{ fontFamily: '"Gugi", sans-serif' }}>
            Beta Pricing
          </p>
        </div>
        <div className="mt-2 lg:grid lg:grid-cols-3 gap-4 lg:max-w-7xl lg:mx-auto xl:max-w-none xl:mx-0 space-y-10 lg:space-y-0">
          {/* Freemium Plan */}
          <div className="mx-6 pb-4 border-2 border-gray-400 rounded-lg dark:bg-gray-700">
            <div className="p-6 bg-gray-400 dark:animate-color-change-2x-dark border-b-2 border-b-gray-400 rounded-t dark:rounded-t-lg">
              <h2 className="text-2xl leading-6 text-center font-medium text-white" style={{ fontFamily: '"Gugi", sans-serif' }}>
                Developer
              </h2>
            </div>
            <div className="flex flex-col justify-between">
              <ul className="~px-2/px-8 pb-4 space-y-2 mx-auto">
                {developer.map((feature) => (
                  <li key={feature} className="flex space-x-3">
                    <CheckIcon className="flex-shrink-0 h-5 w-5 text-cyan-400" aria-hidden="true" />
                    <span className="text-sm text-gray-600 dark:text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="px-14 pt-2 pb-2 cursor-pointer">
              <p
                style={{ fontFamily: '"Gugi", sans-serif' }}
                className="text-align-bottom pt-2"
                onClick={() => {
                  props.setRegisterModal(true);
                  props.setMatrix(true);
                }}
              >
                <span className="text-4xl text-gray-400 hover:text-cyan-400">Sign Up Free</span>
              </p>
            </div>
          </div>
          {/* One-time installation plan 2000 USD per environment */}
          <div className="mx-6 border-2 border-cyan-400 rounded-lg dark:bg-gray-700">
            <div className="p-6 bg-cyan-400 dark:animate-color-change-2x-dark dark:border-b-2 dark:border-b-gray-400 rounded-t dark:rounded-t-lg">
              <h2 className="text-2xl leading-6 text-center font-medium text-white" style={{ fontFamily: '"Gugi", sans-serif' }}>
                Production
              </h2>
            </div>
            <div className="flex flex-col justify-between">
              <ul className="~px-2/px-8 pt-4 space-y-2 mx-auto">
                {core.map((feature) => (
                  <li key={feature} className="flex space-x-3">
                    <CheckIcon className="flex-shrink-0 h-5 w-5 text-cyan-400" aria-hidden="true" />
                    <span className="text-sm text-gray-600 dark:text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="px-14 pt-2 w-full items-center">
              <p className="mt-1 mb-3 text-center">
                <span className="text-2xl font-extrabold text-cyan-400">${installationPrice}</span>
                <span className="text-sm font-medium text-cyan-400"> one-time</span>
              </p>
              <a
                style={{ fontFamily: '"Gugi", sans-serif' }}
                className="cursor-pointer"
                href="https://calendly.com/clint_johnson/retrohook?hide_gdpr_banner=1"
                rel="noreferrer"
                target="_blank"
              >
                <span className="text-2xl text-purple-500 hover:text-purple-400">Schedule</span>
              </a>
            </div>
          </div>
          {/* Monthly Subscription Plan 2000 USD per month for all environments in the account */}
          <div className="mx-6 border-2 border-pink-400 rounded-lg dark:bg-gray-700">
            <div className="p-6 bg-pink-400 dark:animate-color-change-2x-dark dark:border-b-2 dark:border-b-gray-400 rounded-t dark:rounded-t-lg">
              <h2 className="text-2xl leading-6 text-center font-medium text-white" style={{ fontFamily: '"Gugi", sans-serif' }}>
                Updates
              </h2>
            </div>
            <div className="flex flex-col justify-between">
              <ul className="~px-2/px-8 pt-4 space-y-2  mx-auto">
                {updatesFeatures.map((feature) => (
                  <li key={feature} className="flex space-x-3">
                    <CheckIcon className="flex-shrink-0 h-5 w-5 text-cyan-400" aria-hidden="true" />
                    <span className="text-sm text-gray-600 dark:text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="px-14 pt-2 w-full items-center">
              <p className="mt-1 mb-3 text-center">
                <span className="text-2xl font-extrabold text-cyan-400">${recurringPrice}</span>
                <span className="text-sm font-medium text-cyan-400">/month</span>
              </p>
              <p
                style={{ fontFamily: '"Gugi", sans-serif' }}
                className="cursor-pointer"
                onClick={() => {
                  window.$chatwoot.toggle('open');
                }}
              >
                <span className="text-2xl text-pink-400 hover:text-pink-400">Contact Us</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
