
export default {
  url: process.env.API_URL,
  wssUrl: process.env.WSS_URL,
  client_url: process.env.CLIENT_URL,
  stripe_api_key: process.env.STRIPE_API_KEY,
  ENV: process.env.ENV ? process.env.ENV : 'development',
  domain: process.env.AUTH0_DOMAIN ? process.env.AUTH0_DOMAIN : 'retrohook.us.auth0.com',
  clientId: process.env.AUTH0_CLIENT_ID ? process.env.AUTH0_CLIENT_ID : 'DIpZEFp4TLTQjfxdCLHOgnZlstTKFogd',
  scope: process.env.AUTH0_SCOPE
    ? process.env.AUTH0_SCOPE
    : 'read:current_user update:current_user_metadata read:current_user_metadata openid profile email w_member_social read:user user:follow read:org',
  nav: [
    { name: 'Dashboard', href: '/', current: false },
    { name: 'Workflows', href: '/workflows', current: false },
    { name: 'Tunnels', href: '/tunnels', current: false },
  ],
};
