/* eslint-disable react/react-in-jsx-scope */
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};

export default function WorkspaceDropdown(props: any) {
  const [selected, setSelected] = useState(props.selectedWorkspace);
  const [workspaces, setWorkspaces] = useState<any>(props.workspaces);

  useEffect(() => {
    setWorkspaces(props.workspaces);
    setSelected(props.selectedWorkspace);
  }, [props.workspaces, props.selectedWorkspace]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div className=" relative z-10">
          <Listbox.Button className="animate-color-change-2x dark:animate-color-change-2x-dark text-gray-600 dark:text-gray-100 align-middle relative w-full border border-gray-600 rounded-md shadow-sm pl-3 pr-8 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-300 focus:border-cyan-300 sm:text-sm">
            <span className="block align-middle text-sm text-gray-600 dark:text-gray-100">{selected?.name ? selected.name : 'Select Workspace'}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-600 dark:text-gray-100" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="align-middle w-full animate-color-change-2x dark:animate-color-change-2x-dark absolute shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {workspaces.map((workspace: any) => (
                <Listbox.Option
                  className={({ active }) =>
                    classNames(
                      active ? 'text-gray-600 dark:text-gray-100 bg-cyan-400' : 'text-gray-600 dark:text-gray-100',
                      'cursor-default select-none relative py-2 pl-3 pr-9',
                    )
                  }
                  key={workspace.SK}
                  onClick={() => props.setSelectedWorkspaceHandler(workspace)}
                  value={workspace}
                >
                  {({ selected, active }) => (
                    <div>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{workspace.name}</span>
                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-gray-600 dark:text-gray-100' : 'text-cyan-400',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
