import {useState} from "react";
import InputBox from "../components/Base/Elements/Input/InputBox";
import uuid from 'react-uuid';
import useFetch from 'use-http';


export default function Account(props : any) {
  const { post } = useFetch();
  const [subUx, setSubUx] = useState('Settings');
  const [account, setAccount] = useState(props.selectedAccount);

  const handleFirstNameChange = (event : any) => {
    setAccount({ ...account, firstName: event.target.value });
  }

  const saveAccount = async (accountParams: any) => {
    if (!accountParams.hash.value) { accountParams.hash.value = uuid(); accountParams.hash.location = 'MSH.8';}
    const response = await post(`account/update-account/${props.user.sub}`, {accountParams: accountParams});
    if (response.ok && response.data) {
      props.setAccountHandler(response.data);
      if (account.SK == props.selectedAccount.SK) { props.setSelectedAccount(response.data); }
    }
  }
  
  return (
  <div>
    <div className="flex h-full">
      <div className="bg-gray-900 flex-1 min-w-0 flex items-center justify-between px-4">
        <div className="w-full max-w-7xl mx-auto py-4 px-6 flex grow">
          <h2 className="text-lg leading-6 font-semibold text-gray-100 pr-2">
            Account { props.selectedAccount ? `${subUx}` : subUx }
          </h2>
        </div>     
      </div>
    </div>
    <dl> 
    { props.selectedEnvironment && 
      <div className="relative bg-gray-800 border-2 mt-4 rounded border-cyan-300 pb-5 mx-4 overflow-hidden cursor-pointer">
        <div> 
          <div className="mb-6 sm:flex sm:flex-col sm:align-center">
            <h2 className="text-xl p-5 text-gray-200">General Settings</h2>
            <div className="mx-6 border border-top border-cyan-300 mb-6"/>
            <div className=" grid grid-cols-12 gap-2 px-4 pb-6">
              <div className="col-span-11 text-gray-200">
                <InputBox 
                  name="First Name"
                  onChange={handleFirstNameChange}
                  placeholder={ account ? account.firstName : '' }
                  defaultValue={ account ? account.firstName : '' }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      } 
    </dl>
  </div>
  );
}
