/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import PageHeading from '../components/Base/Layout/NavigationElements/PageHeading';
import InputBox from '../components/Base/Elements/Input/InputBox';
import TunnelConnect from '../components/Tunnels/TunnelConnect';
import ProductionRequiredAlert from '../components/Base/Elements/Alerts/ProductionRequiredAlert';
import EnvironmentDropdown from '../components/Base/Layout/NavigationElements/EnvironmentDropdown';
import ConfirmModal from '../components/Base/Elements/Confirmations/ConfirmModal';
import MissingFieldsAlert from '../components/Base/Elements/Alerts/MissingFieldsAlert';
import logo from '../../images/retrohook.png';
import useTunnels from '../hooks/useTunnels';

interface TunnelsProps {
  user: any;
  selectedWorkspace: any;
  selectedEnvironment: any;
  selectedAccount: any;
  admin: boolean;
  notify: () => void;
  setSelectedEnvironment: () => void;
  updateBillingCustomer: () => void;
  logout: () => void;
  account: any;
  environments: any[];
}

export default function Tunnels(props: TunnelsProps) {
  const {
    tunnels,
    showAccessAlert,
    tunnel,
    missingFieldsAlert,
    missingFields,
    currentTunnel,
    isModalOpen,
    loading,
    setShowEnvironmentDropdown,
    setShowAccessAlert,
    setMissingFieldsAlert,
    getTunnels,
    canCreate,
    saveTunnel,
    handleIPChange,
    handleTunnelNameChange,
    handleInternalIPChange,
    showDownloadTunnelConfigClickHandler,
    openModal,
    closeModal,
    confirmDelete,
  } = useTunnels(props);

  if (!props.selectedWorkspace || !props.selectedEnvironment) {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body text-center">
                  <h1 className="display-4">No Workspace Selected</h1>
                  <p className="lead">Please select a workspace from the dropdown above.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <header className="pr-2 bg-cyan-400 dark:animate-color-change-2x-dark min-w-0 md:flex md:items-center">
        <PageHeading title="Tunnels" />
        <div className="flex w-full justify-end">
          {props?.selectedWorkspace.environments.length > 0 && (
            <EnvironmentDropdown
              user={props.user}
              account={props.account}
              admin={props.admin}
              setShowEnvironmentDropdown={setShowEnvironmentDropdown}
              environments={props.environments}
              selectedWorkspace={props.selectedWorkspace}
              updateBillingCustomer={props.updateBillingCustomer}
              selectedEnvironment={props.selectedEnvironment}
              selectedAccount={props.selectedAccount}
              setSelectedEnvironment={props.setSelectedEnvironment}
              logout={props.logout}
            />
          )}
        </div>
        {!loading && props?.selectedEnvironment?.name?.substring(1, 3) !== 'Dev' && (
          <button
            onClick={() => getTunnels()}
            className="ml-1 h-10 w-14 justify-end cursor-pointer p-1 m-2 text-gray-50 bg-cyan-400 rounded border-2 border-cyan-400 
              focus:outline-none"
          >
            <ArrowPathIcon />
          </button>
        )}
      </header>
      {showAccessAlert && <ProductionRequiredAlert closeProdRequiredAlert={() => setShowAccessAlert(false)} />}
      {missingFieldsAlert && missingFields.length > 0 && (
        <MissingFieldsAlert closeMissingFieldsAlert={() => setMissingFieldsAlert(false)} fields={missingFields} />
      )}
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmDelete}
        title="Confirm Delete Tunnel"
        message={`Are you sure you want to delete ${currentTunnel.name}? This cannot be undone.`}
      />
      {loading && (
        <div className="items-center animate-pulse py-48 sm:py-24 md:py-32 lg:py-48 h-screen">
          <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
          <h2 className="mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>
            Retrohook
            <span className="inline align-middle text-xs tracking-tight text-cyan-300 pl-1" />
          </h2>
        </div>
      )}
      {!props.selectedWorkspace && (
        <div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body text-center">
                    <h1 className="display-4">No Workspace Selected</h1>
                    <p className="lead">Please select a workspace from the dropdown above.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!props.selectedEnvironment && (
        <div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body text-center">
                    <h1 className="display-4">No Environment Selected</h1>
                    <p className="lead">Please select a production enabled environment from the dropdown above.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <dl className="p-2 grid grid-cols-4 gap-2 sm:grid-cols-3 lg:grid-cols-4">
        {!loading && props.selectedWorkspace.PK && (
          <div className="relative dark:bg-gray-800 pt-5 px-4 pb-2 sm:pt-6 sm:px-6 rounded-lg overflow-hidden border-gray-700 hover:border-cyan-300 border-2 border-dashed">
            <div className="cursor-pointer">
              <div className="">
                <div className="py-2">
                  <InputBox name="Name" onChange={handleTunnelNameChange} defaultValue={tunnel.deviceName} placeholder={tunnel.deviceNamePlaceholder} />
                </div>
                <div>
                  <div className="pb-2 pt-4">
                    <InputBox
                      name="Public IPv4 Address"
                      onChange={handleIPChange}
                      defaultValue={tunnel.PublicIpv4Address}
                      placeholder={tunnel.PublicIpv4AddressPlaceholder}
                    />
                  </div>
                  <div className="pb-2 pt-4">
                    <InputBox
                      name="Private IPV4 Address"
                      onChange={handleInternalIPChange}
                      defaultValue={tunnel.LocalIpv4Address}
                      placeholder={tunnel.LocalIpv4AddressPlaceholder}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-1 pb-2 sm:px-2 w-full">
              {canCreate() ? (
                <button
                  className="w-full bg-cyan-300 hover:bg-cyan-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={saveTunnel}
                >
                  Generate
                </button>
              ) : (
                <button
                  className="w-full bg-gray-500 hover:bg-cyan-100 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setShowAccessAlert(true)}
                >
                  {loading ? 'Generating...' : 'Generate'}
                </button>
              )}
            </div>
          </div>
        )}
        {!loading &&
          tunnels.map((tunnel) => (
            <div
              key={tunnel.vpnConnectionId}
              className="relative dark:bg-gray-800 pt-5 px-4 pb-4 sm:pt-6 sm:px-6 rounded overflow-hidden hover:border-cyan-300 border-2 border-gray-700"
            >
              {!tunnel.showDownloadTunnelConfig ? (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    showDownloadTunnelConfigClickHandler(tunnel);
                  }}
                >
                  <dt>
                    <div className="absolute rounded-md p-1" />
                    <p className="text-center text-lg font-semibold text-gray-600 dark:text-gray-100 truncate">
                      {tunnel.name ? tunnel.name.slice(0, 24) : tunnel.Tags[0].Value.slice(0, 24)}
                    </p>
                    <button
                      className="absolute top-0 right-0 m-2 border-gray-500 border hover:bg-pink-500 hover:text-white hover:border-orange-500 text-gray-500 font-bold text-xs py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        openModal(tunnel);
                      }}
                    >
                      X
                    </button>
                    <p className="text-center text-xs text-cyan-400">{tunnel.state.toUpperCase()}</p>
                    <div className="divide-y divide-solid">
                      <div className="text-center">
                        <p className="text-xs text-gray-500 dark:text-gray-200">
                          Primary IPv4: &nbsp;<span className="text-cyan-400">{tunnel.tunnel1_ip}</span>
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-200">
                          Secondary IPv4: &nbsp;<span className="text-cyan-400">{tunnel.tunnel2_ip}</span>
                        </p>
                      </div>
                    </div>
                    <p className="text-xs text-center text-gray-600 dark:text-gray-100">
                      {tunnel.Tags[0].Value} IPv4:{' '}
                      <span className=" text-gray-500 dark:text-gray-200 text-sm">
                        <span className="text-pink-400">{tunnel.customer_ip}</span>
                      </span>
                    </p>
                    {tunnel.Routes && (
                      <p className="text-xs text-center text-gray-600 dark:text-gray-100">
                        {tunnel.Tags[0].Value} Subnet:{' '}
                        <span className=" text-gray-500 dark:text-gray-200 text-sm">
                          <span className="text-pink-400">{tunnel.Routes[0].DestinationCidrBlock}</span>
                        </span>
                      </p>
                    )}
                    {tunnel.Options && (
                      <p className="text-xs text-center text-gray-600 dark:text-gray-100">
                        Retrohook Subnet: <span className=" text-gray-500 dark:text-gray-200 text-sm">{tunnel.Options.RemoteIpv4NetworkCidr}</span>
                      </p>
                    )}
                    <p className="text-xs text-center text-gray-600 dark:text-gray-100">
                      IKE:{' '}
                      <span className=" text-gray-500 dark:text-gray-200 text-sm">
                        {`${tunnel.ike_auth_protocol} | ${tunnel.ike_encryption_protocol} | ${tunnel.ike_diffie_helm_group}`}
                      </span>
                    </p>
                    <p className="text-xs text-center text-gray-600 dark:text-gray-100">
                      IPSec:{' '}
                      <span className=" text-gray-500 dark:text-gray-200 text-sm">
                        {tunnel.tunnel1_psk ? 'psk' : ''} | {tunnel.ipsec_auth_protocol}
                      </span>
                    </p>
                    {tunnel.showPsk && (
                      <p className="text-xs text-gray-500 dark:text-gray-200 bg-red-800">
                        PSK: <span className="text-gray-500 dark:text-gray-200 text-xs">{tunnel.tunnel1_psk}</span>
                      </p>
                    )}
                  </dt>
                </div>
              ) : null}
              {tunnel.showDownloadTunnelConfig && (
                <TunnelConnect
                  tunnel={tunnel}
                  user={props.user}
                  accounts={props.accounts}
                  account={props.account}
                  environments={props.environments}
                  selectedEnvironment={props.selectedEnvironment}
                  selectedAccount={props.selectedAccount}
                  showDownloadTunnelConfigClickHandler={showDownloadTunnelConfigClickHandler}
                  logout={props.logout}
                />
              )}
            </div>
          ))}
      </dl>
    </>
  );
}
