/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import cloudPatient from '../../../images/cloud-patient.svg';

export default function RetrohookHome(props: any) {
  const { classNames, matrix, matrixCSS, signUp, setMatrix, setShowTermsOfService } = props;
  return (
    <div className="mx-auto px-8">
      <div className="inline-flex">
        <div className="mx-auto inline px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:items-center">
          <div className="py-20 ~py-12/py-48">
            <h1
              className={classNames(
                matrix ? matrixCSS : '',
                'mt-4 text-4xl tracking-tight font-bold text-gray-700 dark:text-white sm:mt-5 sm:text-xl sm:tracking-tight lg:mt-6 md:text-4xl lg:text-5xl xl:text-5xl xl:tracking-tight',
              )}
            >
              <span style={{ fontFamily: '"Gugi", sans-serif' }} className="block text-gray-100">
                Instant Integration
              </span>
              <span
                style={{ fontFamily: '"Gugi", sans-serif' }}
                className="bg-clip-text text-transparent bg-gradient-to-r from-gray-100 to-cyan-400 dark:from-gray-200 dark:to-pink-600"
              >
                Infinite Innovation
              </span>
            </h1>
            <p className={classNames(matrix ? matrixCSS : '', 'mb-4 text-base text-cyan-300 dark:text-gray-300 ~text-xl/text-lg xl:text-xl')}>
              Empower your product with seamless, secure hl7 exchange.
            </p>
            <div>
              <div className="mt-2 flex mx-20">
                <div className="rounded-md shadow">
                  <button
                    type="button"
                    onClick={() => {
                      signUp();
                      setMatrix(!matrix);
                    }}
                    className="w-full flex items-center px-8 py-3 border-transparent text-base font-medium rounded-md cursor-pointer border-2 border-pink-400 hover:border-pink-300 text-white bg-pink-400 md:text-lg"
                  >
                    Free Developer Account
                  </button>
                </div>
              </div>
              <p className="text-xs mx-16 text-white dark:text-gray-300">
                by signing up, you agree to our{'  '}
                <span
                  onClick={() => {
                    setShowTermsOfService(true);
                    setMatrix(!matrix);
                  }}
                  className="text-purple-500 cursor-pointer hover:underline"
                >
                  terms of service
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto px-4 sm:max-w-2xl sm:px-6 lg:px-0">
          <img className="absolute right-10 top-10 ~opacity-20/100" src={cloudPatient} alt="data UP UP" />
        </div>
      </div>
    </div>
  );
}
