/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch } from 'react-router-dom';
import { Dashboard, Workflows, Tunnels, Workspace, Account } from '../../../../app/pages';
import { PrivateRoute } from '../../../../app/components/Base/Layout/PrivateRoute';

interface Props {
  account: any;
  environments: any;
  selectedAccount: any;
  setAccount: any;
  updateBillingCustomer: any;
  setSelectedWorkspace: any;
  selectedEnvironment: any;
  setSelectedEnvironment: any;
  setupEnvironmentsHandler: any;
  setSelectedAccount: any;
  redirectToURL: any;
  history: any;
  selectedWorkspace: any;
  getAccount: any;
  notify: any;
  environmentChanged: any;
  setEnvironmentChanged: any;
  user: any;
  fetchAccount: any;
  setEnvironments: any;
}

export default function PrivateRoutes(props: Props) {
  const {
    account,
    environments,
    selectedAccount,
    setAccount,
    updateBillingCustomer,
    setSelectedWorkspace,
    selectedEnvironment,
    setSelectedEnvironment,
    setSelectedAccount,
    redirectToURL,
    selectedWorkspace,
    notify,
    environmentChanged,
    setEnvironmentChanged,
    user,
    fetchAccount,
    setEnvironments,
    history,
  } = props;
  return (
    <main className="animate-color-change-2x dark:animate-color-change-2x-dark flex-grow">
      <Switch>
        <PrivateRoute
          exact
          path="/"
          render={(routeProps: any) => (
            <Dashboard
              {...routeProps}
              user={user}
              account={account}
              setAccount={setAccount}
              environments={environments}
              selectedAccount={selectedAccount}
              selectedWorkspace={selectedWorkspace}
              updateBillingCustomer={updateBillingCustomer}
              selectedEnvironment={selectedEnvironment}
              setSelectedEnvironment={setSelectedEnvironment}
              notify={notify}
            />
          )}
        />
        <PrivateRoute
          path="/workflows"
          render={(routeProps: any) => (
            <Workflows
              {...routeProps}
              user={user}
              account={account}
              setAccount={setAccount}
              environments={environments}
              selectedAccount={selectedAccount}
              selectedWorkspace={selectedWorkspace}
              selectedEnvironment={selectedEnvironment}
              setSelectedEnvironment={setSelectedEnvironment}
              notify={notify}
            />
          )}
        />
        <PrivateRoute
          path="/tunnels"
          render={(routeProps: any) => (
            <Tunnels
              {...routeProps}
              user={user}
              account={account}
              setAccount={setAccount}
              environments={environments}
              selectedAccount={selectedAccount}
              selectedEnvironment={selectedEnvironment}
              selectedWorkspace={selectedWorkspace}
              setSelectedEnvironment={setSelectedEnvironment}
              notify={notify}
            />
          )}
        />
        <PrivateRoute
          path="/workspace"
          render={(routeProps: any) => (
            <Workspace
              {...routeProps}
              user={user}
              account={account}
              setAccount={setAccount}
              getAccount={fetchAccount}
              environments={environments}
              workspaces={account?.workspaces}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              selectedWorkspace={account?.workspaces[0]}
              selectedEnvironment={selectedEnvironment}
              setSelectedEnvironment={setSelectedEnvironment}
              setSelectedWorkspace={setSelectedWorkspace}
              setupEnvironmentsHandler={setEnvironments}
              redirectToURL={redirectToURL}
              notify={notify}
              environmentChanged={environmentChanged}
              setEnvironmentChanged={setEnvironmentChanged}
            />
          )}
        />
        <PrivateRoute
          path="/account"
          render={(routeProps: any) => (
            <Account
              {...routeProps}
              user={user}
              account={account}
              setAccount={setAccount}
              environments={environments}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              selectedEnvironment={selectedEnvironment}
              setSelectedEnvironment={setSelectedEnvironment}
              history={history}
              notify={notify}
            />
          )}
        />
      </Switch>
    </main>
  );
}
