import React from 'react';
import screenshot from '../../../images/screenshot.png';
import screenshotLight from '../../../images/screenshot-light.png';

export default function RetrohookOverview(props: any) {
  const { classNames, effectiveTheme, matrix, matrixCSS } = props;
  return (
    <div className="mx-auto max-w-md px-4 text-center  sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
      <div className={classNames(matrix ? matrixCSS : '', 'mx-auto')}>
        <h2 className="text-xl font-semibold text-pink-500 dark:text-cyan-300">⚡️ Harness Real Time EHR Event Automation ⚡️</h2>
        <p
          style={{ fontFamily: '"Gugi", sans-serif' }}
          className="mt-2 text-3xl font-bold text-gray-600 dark:text-gray-200 tracking-tight sm:text-4xl sm:tracking-tight"
        >
          🔥 Reignite HL7v2.x With Clicks, Not Code.
        </p>
        <p className="mt-2 max-w-prose mx-auto text-xl text-gray-400">Quickly and securely connect EHR data to modern technologies you ❤️.</p>
      </div>
      <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
        <img
          className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
          src={effectiveTheme === 'dark' ? screenshot : screenshotLight}
          alt="A view of Retrohook"
        />
      </div>
    </div>
  );
}
