import React from 'react';
import hospital from '../../../images/hospital.png';
import happy from '../../../images/happy.png';
import teams from '../../../images/teams.png';
import serverRoom from '../../../images/secure-tunnel.png';
import cool from '../../../images/cool.png';

const mainFeatures = [
  {
    id: 1,
    title: 'No-Code Integrations',
    href: 'https://docs.retrohook.com/docs/workflows',
    category: { name: 'Built for Everyone', href: 'https://docs.retrohook.com/docs/workflows' },
    imageUrl: hospital,
    preview: "Retrohook's workflow engine allow you to route and map legacy hl7v2 data into useful HTTPS requests (webhooks), or AWS services.",
  },
  {
    id: 2,
    title: 'Simple Secure Tunnels',
    href: 'https://docs.retrohook.com/docs/tunnels',
    category: { name: 'IPSec IKEv2 VPN', href: 'https://docs.retrohook.com/docs/tunnels' },
    imageUrl: serverRoom,
    preview:
      'Retrohook ensures secure interfaces by supporting both old and new security tech. Easily create safe MLLP tunnels with its user-friendly UI and connection agent.',
  },
  {
    id: 3,
    title: 'Private AWS Deployments',
    href: 'https://docs.retrohook.com/docs/workspaces',
    category: { name: 'Leverage Your AWS', href: 'https://docs.retrohook.com/docs/workspaces' },
    imageUrl: happy,
    preview: 'Deploy a Retrohook instance into your own AWS environment for enhanced compliance and security controls.',
  },
  {
    id: 4,
    title: 'Team Mode',
    href: 'https://docs.retrohook.com/docs/intro#workspaces--environments',
    category: { name: 'Shared Workspaces', href: 'https://docs.retrohook.com/docs/intro#workspaces--environments' },
    imageUrl: teams,
    preview: 'Share your workspace and collaborate with others. Team mode also provides granular controls for managing access.',
  },
];

export default function FeaturesGrid() {
  return (
    <div className="relative items-center">
      <div className=" py-8 mb-16">
        <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-lg font-semibold text-cyan-500" style={{ fontFamily: '"Gugi", sans-serif' }}>
            Learn more about how Retrohook
          </h2>
          <p className="mt-2 text-3xl font-bold text-pink-400 dark:text-gray-200 tracking-tight sm:text-4xl sm:tracking-tight">
            <span className="mx-2" style={{ fontFamily: '"Gugi", sans-serif' }}>
              Accelerates HL7v2 Integration
            </span>
          </p>
          <p className="mb-10 mt-5 mx-auto max-w-prose text-xl text-gray-500">
            <span className="mx-2">
              Work as a team or individual to deploy workflow automation in a fraction of the time with a DIY approach, from the comfort of your own
              infrastructure!
            </span>
          </p>
        </div>
        <div className="mx-auto px-4 grid gap-8 sm:px-6 lg:px-8 sm:grid-cols-1 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 max-w-7xl">
          {mainFeatures.map((post) => (
            <div
              key={post.id}
              className="flex flex-col rounded-lg ring-2 ring-gray-200 dark:ring-cyan-400 overflow-hidden hover:animate-pulse hover:ring-2 hover:ring-cyan-300 cursor-pointer"
            >
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
              </div>
              <div className="flex-1 p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-pink-500 dark:text-cyan-400">
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </p>
                  <a href={post.href} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-700 dark:text-gray-200">{post.title}</p>
                    <p className="text-base text-gray-500 dark:text-gray-400">{post.preview}</p>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-cyan-500 bg-gradient-to-r from-cyan-300 lg:z-10">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 grid grid-cols-3 lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="lg:-mt-28 md:-mt-14 mt-32">
            <div className="mx-auto px-4 p-0 lg:h-full">
              <div className="aspect-w-10 aspect-h-10 overflow-hidden sm:aspect-w-7 sm:aspect-h-7 lg:aspect-none lg:h-full">
                <img className="max-h-96 sm:max-h-84 md:max-h-84" src={cool} alt="Retrohook doc" />
              </div>
            </div>
          </div>
          <div className="lg:m-0 col-span-2 lg:pl-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 mt-8 lg:max-w-none">
              <blockquote>
                <div>
                  <p className="mt-4 sm:text-sm md:text-lg lg:text-2xl xl:text-2xl font-medium text-white">
                    <svg className="h-6 w-6 inline m-3 text-white opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    Taking advantage of the immense power of HL7 v2.x&apos;s event driven messaging does not have to be difficult.
                    <br />
                    <br />
                    Retrohook&apos;s abstractions provide rapid time to value and shorter product on ramps.
                    <svg className="h-6 w-6 inline rotate-180 m-3 text-white opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                  </p>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
