import React from 'react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="border-t-cyan-400 border-t-2 bg-gray-200 dark:bg-gray-800 w-full mx-auto py-4 px-4 overflow-hidden sm:px-6 lg:px-8">
      <div className="my-5">
        <p className="text-center text-base leading-6 text-gray-600 dark:text-gray-100">&copy; {currentYear} Retrohook. All rights reserved</p>
      </div>
    </div>
  );
}
