/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/function-component-definition */
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import logo from '../../../images/retrohook.png';

const TermsOfService = () => {
  return (
    <div className="animate-in fade-in text-xs text-gray-500 dark:text-cyan-300 dark:bg-gray-600 rounded-sm p-2">
      <div className="text-xl font-bold">Terms of Service</div>
      <div className="text-md mt-2">Last Updated: 10/20/23</div>
      <p>
        Welcome to Retrohook! These Terms of Service ("Terms") govern your use of the Retrohook platform ("Service"), provided by 1PuttHealth LLC ("Company",
        "we", "us", "our").
      </p>

      <div className="text-xl mt-2">Acceptance of Terms</div>
      <p>
        By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the
        Service.
      </p>

      <div className="text-lg mt-2">Changes to Terms</div>
      <p>We reserve the right to modify these Terms at any time. Your continued use of the Service constitutes your acceptance of any changes.</p>

      <div className="text-lg mt-2">Privacy Policy</div>
      <p>Your use of the Service is also governed by our Privacy Policy, which can be requested.</p>

      <div className="text-lg mt-2">User Responsibilities</div>
      <p>You are responsible for all activities that occur under your account. You agree not to engage in any illegal or prohibited conduct.</p>

      <div className="text-lg mt-2">Limitation of Liability</div>
      <p>To the fullest extent permitted by law, the Company shall not be liable for any indirect, incidental, or consequential damages.</p>

      <div className="text-lg mt-2">Governing Law</div>
      <p>These Terms are governed by the laws of the State of Delaware, without regard to its conflict of laws principles.</p>

      <div className="text-lg mt-2">Contact Information</div>
      <p className="mb-6">
        For any questions about these Terms, please contact us at&nbsp;
        <a href="mailto:support@retrohook.com">support@retrohook.com </a>
      </p>
    </div>
  );
};

export default function TermsOfServiceModal(props: any) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {
          props.closeModal;
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom items-center w-1/2 max-h-1/3 animate-color-change-2x dark:animate-color-change-2x-dark rounded-lg text-left  shadow-xl transform transition-all sm:align-middle sm:p-2">
              <div className="px-1 inline-flex items-center">
                <div className="animate-color-change-2x dark:animate-color-change-2x-dark px-6 pb-2">
                  <div className="items-center">
                    <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
                    <h2 className="mt-0 text-center text-5xl font-bold text-gray-600 dark:text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>
                      Retrohook
                    </h2>
                  </div>
                  <TermsOfService />
                </div>
              </div>
              <div className="px-4 pb-2">
                <button
                  type="button"
                  className="mt-6 w-full inline-flex justify-center rounded-sm shadow-sm py-2 bg-pink-400 text-base font-medium text-gray-100 hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-300 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    props.closeModal();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
